import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BlogItem from "./BlogItem";
import { useLocation, Link } from 'react-router-dom';
import axiosConfig from "../../Service/axiosConfig";
import BreadCrumb from "../../components/BreadCrumb";
import { Helmet } from "react-helmet";

const BlogDetail = ({ item }) => {
    const location = useLocation();
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    const GetRelatedList = async () => {
        try {
            const response = await axiosConfig.get(`/blogapp/blog/?page_size=3&category=${''}`)
            setRelatedBlogs(response.data.results)
        } catch (error) {
            console.log("Error getting related blog data :", error)
        }
    }

    useEffect(() => {
        GetRelatedList();
    }, [])
    const prev_sub_page = {
        link: `/blog/${item?.category?.slug}`,
        text: item?.category?.slug,
        state: { category: item?.category?.id } // Example for dynamic generation
    };
    return (
        <div>
            {item &&
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{item?.category?.title}</title>
                    <meta property="og:title" content={item?.title} />
                    <meta property="og:image" content={item?.image.image} />
                    <meta property="og:image:secure_url" content={item?.image.image} />
                    <meta property="og:image:width" content="600" />
                    <meta property="og:image:height" content="600" />
                    <meta property="og:image:alt" content={item?.title} />
                    <meta property="og:image:type" content="image/jpeg" />
                    
                </Helmet>
            }
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb active_page={item?.slug} prev_sub_page={prev_sub_page} />
            </section>
            <div className="container">
                <div className="blog-detail">
                    <h2>{item?.title}</h2>
                    {/* <div className="detail-top">
                        <img src={require('../../assets/images/blog1.png')} />
                        <div>
                            <p>Oscar Wallis</p>
                            <span>Published</span>
                            <span>.</span>
                            <span>4 min read</span>
                            <span>.</span>
                            <span>2 days ago</span>
                        </div>
                    </div> */}
                    <img src={item?.image.image} className="img-fluid" />
                    <div dangerouslySetInnerHTML={{ __html: item?.content }} className="article" />
                    <span className="topic">#{item?.category.title}</span>
                    {/* <span className="topic">#Medicine</span>
                    <span className="topic">#Humor</span>
                    <span className="topic">#Emergency Room</span>
                    <span className="topic">#Satire</span>
                    <span className="topic">#Comedy</span> */}
                    <h3 className="recommended">Recommended Topics</h3>
                    {relatedBlogs.length > 0 &&
                        relatedBlogs.map((blog) =>
                            <>
                                <div className="detail-list">
                                    <div className="list">
                                        <BlogItem item={blog} />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    )
}

export default BlogDetail;